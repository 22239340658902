









import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import moment from "moment";
import productComboProductItemRoles from "@/constants/productComboProductItemRoles";
import makeSelectOptions from "@common/util/makeSelectOptions";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import Bill from "./wholesale-orders/partials/Bill.vue";

export default Vue.extend({
  data() {
    return {
      userManager,
      tableOptions: null,
      item: null,
    };
  },
  methods: {
    makeTableOptions(item) {
      const item2 = item;

      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Đơn đặt hàng sỉ",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              code: {
                attrs: {
                  label: "Mã đơn hàng",
                },
                rules: {
                  match: {},
                },
              },
              customerId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Khách hàng",
                  required: true,
                  "item-value": "_id",
                  "item-text": "code",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("wholesaleCustomers", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              mktTeamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktDepartmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          displayFields: {
            code: {
              text: "Mã đơn hàng",
            },
            customer: {
              text: "Khách hàng",
              options: {
                subProp: "customer.code",
              },
            },
            time: {
              text: "Order Date",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            paid: {
              text: "Đã thanh toán",
              options: {
                boolean: true,
              },
            },
            products: {
              text: "Sản phẩm",
              options: {
                labels(_, item) {
                  return [""].concat(item.items?.map((pi) => pi.product?.sku || "N/A"));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            productGroups: {
              text: "Nhóm sản phẩm",
              options: {
                labels(_, item) {
                  return [""].concat(item.items?.map((pi) => pi.product?.productGroup.sku || "N/A"));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            types: {
              text: "Loại sản phẩm",
              options: {
                labels(_, item) {
                  return [""].concat(item.items?.map((pi) => productComboProductItemRoles[pi.type] || "N/A"));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            counts: {
              text: "Số lượng",
              options: {
                labels(_, item) {
                  return [""].concat(item.items?.map((pi) => pi.count));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            prices: {
              text: "Đơn giá trước discount",
              options: {
                labels(_, item) {
                  return [""].concat(item.items?.map((pi) => Vue.filter("currency")(pi.price)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            discount: {
              text: "Discount",
              options: {
                labels(_, item) {
                  return [""].concat(item.items?.map((pi) => `${pi.discountPercentage}%`));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            pricesAfterDiscount: {
              text: "Đơn giá sau discount",
              options: {
                labels(_, item) {
                  return [""].concat(item.items?.map((pi) => Vue.filter("currency")(pi.price * (1 - (pi.discountPercentage || 0) / 100))));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            totalPrices: {
              text: "Tổng giá trước discount",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.items.reduce((total, pi) => total + pi.price * pi.count, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.items?.map((pi) => Vue.filter("currency")(pi.price * pi.count)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            totalPricesAfterDiscount: {
              text: "Tổng giá sau discount",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.items.reduce((total, pi) => total + pi.price * (1 - (pi.discountPercentage || 0) / 100) * pi.count, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.items?.map((pi) => Vue.filter("currency")(pi.price * (1 - pi.discountPercentage / 100) * pi.count)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            realTotalPrice: {
              text: "Số tiền thực nhận",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.items.reduce((total, pi) => total + pi.realTotalPrice, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.items?.map((pi) => Vue.filter("currency")(pi.realTotalPrice)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            cashback: {
              text: "Cashback",
              options: {
                filter: "currency",
              },
            },
            totalPricesAfterCashback: {
              text: "Tổng giá sau cashback",
              options: {
                preTransform(_, item) {
                  return item.items.reduce((total, pi) => total + pi.price * (1 - (pi.discountPercentage || 0) / 100) * pi.count, 0) - (item.cashback || 0);
                },
                filter: "currency",
              },
            },
            mktUser: {
              text: "User MKT",
              options: {
                subProp: "mktUser.name",
                sortBy: "mktUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.mktUser && item.mktUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            mktTeam: {
              text: "Team",
              options: {
                subProp: "mktTeam.name",
                sortBy: "mktTeamId",
              },
            },
            mktDepartment: {
              text: "Phòng",
              options: {
                subProp: "mktDepartment.name",
                sortBy: "mktDepartmentId",
              },
            },
            mktPlatform: {
              text: "Nền tảng MKT",
              options: {
                subProp: "mktPlatform.name",
                sortBy: "mktPlatformId",
              },
            },
            warehouse: {
              text: "Kho",
              options: {
                subProp: "warehouse.name",
              },
            },
            customerCode: {
              text: "Mã KH",
              options: {
                subProp: "customer.code",
              },
            },
            customerName: {
              text: "Tên",
              options: {
                subProp: "customer.name",
              },
            },
            customerPhone: {
              text: "SĐT",
              options: {
                subProp: "customer.phoneNumber",
              },
            },
            customerAddress: {
              text: "Địa chỉ",
              options: {
                subProp: "customer.address",
              },
            },
            paymentPartner: {
              text: "Đối tac thanh toán",
              options: {
                label: (value) => value.name,
              },
            },
            paymentAccount: {
              text: "Tài khoản thanh toán",
              options: {
                label: (value) => value.name,
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker({ startDate, endDate }) {
                        return `${moment(startDate).format("YYYY-MM-DD")} - ${moment(endDate).format("YYYY-MM-DD")}`;
                      },
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              pricing: {
                attrs: {},
                content: {
                  icon: "mdi-cash-multiple",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "BILL RECEIPT",
                    },
                  },
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      toolbar: {
                        title: "BILL RECEIPT",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.code;
                        },
                      },
                      content: {
                        type: Bill,
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.item = item;
                          return attrs;
                        },
                        attrs: {},
                      },
                    },
                  },
                },
              },
              approvePayment: userManager.checkRole(["kt"]) && {
                ext: {
                  condition: (_, item) => {
                    return !item.paid;
                  },
                },
                attrs: {},
                content: {
                  icon: "mdi-check-decagram",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Xác nhận thanh toán",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      toolbar: {
                        title: "Xác nhận thanh toán",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.code;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          item.items?.forEach((pi) => {
                            pi.totalPriceAfterDiscount = pi.price * (1 - pi.discountPercentage / 100) * pi.count;
                          });
                          attrs.xData = new DataContainer(item);
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    paymentPartnerId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Đối tác",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call("paymentPartners", "findAll", {
                                                payload: JSON.stringify({
                                                  limit,
                                                  search,
                                                  filters: [
                                                    {
                                                      key: "_id",
                                                      operator: "equal_to",
                                                      value,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    paymentAccountId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "TKNH",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call("paymentAccounts", "findAll", {
                                                payload: JSON.stringify({
                                                  limit,
                                                  search,
                                                  filters: [
                                                    {
                                                      key: "_id",
                                                      operator: "equal_to",
                                                      value,
                                                    },
                                                  ],
                                                }),
                                              });
                                              items.forEach((item) => {
                                                item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    items: {
                                      type: "XArrayInput",
                                      attrs: {
                                        label: "",
                                        xOptions: {
                                          content: {
                                            deleteDisabled: true,
                                            addItemBtn: {
                                              ext: {
                                                hidden: true,
                                              },
                                            },
                                            template: {
                                              formOptions: {
                                                content: {
                                                  colLength: 2,
                                                },
                                              },
                                            },
                                            itemProperties: {
                                              productId: {
                                                type: "XAutocomplete",
                                                attrs: {
                                                  label: "Sản phẩm",
                                                  "item-value": "_id",
                                                  "item-text": "sku",
                                                  "item-disabled": "_",
                                                  required: true,
                                                  readonly: true,
                                                  xOptions: {
                                                    content: {
                                                      async itemsSuggestor(search, value, limit) {
                                                        const { items } = await coreApiClient.call("products", "findAll", {
                                                          payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            filters: [
                                                              {
                                                                key: "_id",
                                                                operator: "equal_to",
                                                                value,
                                                              },
                                                              {
                                                                key: "countryId",
                                                                operator: "equal_to",
                                                                value: item.countryId,
                                                              },
                                                            ],
                                                          }),
                                                        });
                                                        return items;
                                                      },
                                                    },
                                                  },
                                                },
                                              },
                                              type: {
                                                type: "select",
                                                attrs: {
                                                  text: "Loại sản phẩm",
                                                  items: makeSelectOptions(productComboProductItemRoles),
                                                  required: true,
                                                  readonly: true,
                                                },
                                              },
                                              count: {
                                                type: "integer",
                                                ext: {
                                                  colLength: 1,
                                                },
                                                attrs: {
                                                  label: "Số lượng",
                                                  readonly: true,
                                                },
                                              },
                                              price: {
                                                type: "number",
                                                ext: {
                                                  colLength: 1,
                                                },
                                                attrs: {
                                                  label: "Đơn giá",
                                                  readonly: true,
                                                },
                                              },
                                              discountPercentage: {
                                                type: "number",
                                                attrs: {
                                                  label: "Discount (%)",
                                                  readonly: true,
                                                },
                                              },
                                              totalPriceAfterDiscount: {
                                                type: "number",
                                                attrs: {
                                                  label: "Tổng giá sau discount",
                                                  readonly: true,
                                                },
                                              },
                                              realTotalPrice: {
                                                type: "number",
                                                attrs: {
                                                  label: "Số tiền thực nhận",
                                                  required: true,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Xác nhận thanh toán",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "wholesaleOrders",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        ...item,
                                        paymentPartnerId: data.paymentPartnerId,
                                        paymentAccountId: data.paymentAccountId,
                                        items: item.items.map((pi, index) => ({
                                          ...pi,
                                          ...data.items[index],
                                        })),
                                        paymentFlag: true,
                                        paid: true,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              exportDeliveryNote: {
                attrs: {},
                content: {
                  icon: "mdi-download",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    coreApiClient.download("wholesaleOrders", `${item._id}/deliveryNote`);
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: `Export Delivery Note`,
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: item._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("wholesaleOrders", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              item.marketId = item2._id;
              return await coreApiClient.call("wholesaleOrders", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "wholesaleOrders",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("wholesaleOrders", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              code: {
                attrs: {
                  label: "Mã đơn hàng",
                  required: true,
                },
              },
              customerId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Khách hàng",
                  "item-value": "_id",
                  "item-text": "code",
                  "item-disabled": "_",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("wholesaleCustomers", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              time: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Order Date",
                  required: true,
                },
              },
              items: {
                type: "XArrayInput",
                attrs: {
                  label: "",
                  xOptions: {
                    content: {
                      template: {
                        formOptions: {
                          content: {
                            colLength: 3,
                          },
                        },
                      },
                      itemProperties: {
                        productId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "item-disabled": "_",
                            required: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      limit,
                                      search,
                                      filters: [
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value,
                                        },
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: item.countryId,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        type: {
                          type: "select",
                          attrs: {
                            text: "Loại sản phẩm",
                            items: makeSelectOptions(productComboProductItemRoles),
                            required: true,
                          },
                        },
                        description: {
                          type: "textLarge",
                          attrs: {
                            label: "Mô tả",
                          },
                        },
                        count: {
                          type: "integer",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                      },
                      rules: {
                        custom: [
                          async (value) => {
                            return value && value.length;
                          },
                        ],
                      },
                    },
                  },
                },
              },
              cashback: {
                type: "number",
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: item.countryId,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              content: {
                fieldNames: ["code", "customerId", "time", "cashback", "warehouseId", "items"],
              },
            },
            editForm: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              content: {
                fieldNames: ["code", "customerId", "time", "cashback", "warehouseId", "items"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "code",
            },
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.item);
  },
});
